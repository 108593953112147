@import "src/styles/utils";

.App {
  @include black-background;
  position: relative;
  margin: 0;
  padding: 0;
  top: 0;
}

body, #root, .App {
  height: var(--inner-height);
}

body {
  min-height: unset !important;
  margin: 0;
  font-family: var(--root-font-family);
  font-weight: var(--root-font-weight);
  font-size: var(--root-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (orientation: landscape) {
  .App {
    margin: 0 auto;
    min-height: unset !important;
    overflow: auto;
  }
}
