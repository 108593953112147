@import "src/styles/utils";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: $page-top-padding-mobile;

  .heading {
    @include h2;
  }

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }
}

.buttonBox {
  position: relative;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 480px;
  margin-top: 15px;

  border-radius: var(--button-border-radius);
  border: 1px solid $primary-color;

  @include is-tablet-portrait {
    width: 100%;
  }
  @include is-desktop {
    width: 100%;
  }
  @media (max-height: 670px) {
    margin-top: 0;
  }
}

.navButton {
  @include flex-centered(false, true);
  @include small-text(false);
  flex: 1;
  width: 45%;
  height: 30px;

  background-color: transparent;
  border-radius: 6px;
  border: none;

  transition: text-decoration-color 0.3s ease-out, border-radius, opacity 0.3s ease;

  &.isActive {
    z-index: 1;
    background-color: $primary-color;
    font-weight: bold;
  }

  &:hover {
    opacity: 0.7;
  }
}

.tableBox {
  position: relative;
  width: 100%;
  max-width: 480px;
  overflow: auto;
  height: 40vh;

  @media (max-height: 670px) {
    height: 30vh;
  }
}

.dateBox {
  @include flex-centered(false, false);
  justify-content: space-between;
  width: 85%;
  max-width: 480px;
  padding: 0;
  min-height: 50px;

  .date {
    @include small-text(false);
    color: $primary-color;
    text-align: left;

    @media (max-height: 670px) {
      margin: 0;
    }
  }

  .dateLabel {
    @include small-text(false);
    color: $primary-color;
    text-align: right;

    @media (max-height: 670px) {
      margin: 0;
    }
  }

  .emptyDate {
    @include small-text(false);
    color: transparent;
  }

  @include is-tablet-portrait {
    width: 100%;
  }

  @include is-desktop {
    width: 100%;
  }

  @media (max-height: 670px) {
    min-height: 30px;
  }
}

.button {
  @include flex-centered(false, true);
  @include sub-heading(true);
  width: 100%;
  max-width: 480px;
  height: 30px;

  background-color: transparent;
  border: none;

  color: $accent-color;
  text-align: center;
  text-transform: uppercase;

  transition: text-decoration-color 0.3s ease-out, border-radius, opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  .leaderboardIcon {
    transform: rotate(180deg);
  }
}

.bottomButtonBox {
  @include flex-centered(true, true);
  width: 85%;
  max-width: 480px;

  .prizesButton {
    text-transform: uppercase;
  }

  .playButton {
    @include tertiary-text();
    margin-top: 0.8rem;

    & p {
      @include small-text(false);
      font-weight: 500;
      color: $primary-text-color;
    }
  }

  @include is-tablet-portrait {
    width: 100%;
  }

  @include is-desktop {
    width: 100%;
  }
}

