@import 'src/styles/utils';

.table {
  position: relative;
  width: 100%;
  padding: 0 35px 0 35px;
  margin-top: 1rem;
}

.row {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  color: $light-text-color;
  font-family: $root-font-family;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;

  .row:last-child {
    left: 10%;
  }
}