@import 'src/styles/utils';

.coinBox {
  @include flex-centered(false, true);
  flex-wrap: wrap;
  gap: 10px;

  width: 85%;
  height: 134px;

  @include is-tablet-portrait {
    width: 100%;
    gap: 0;
  }
}