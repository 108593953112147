@import "src/styles/utils";

.coin {
  position: relative;
  display: block;
  margin: 5px;

  @include is-tablet-portrait {
    margin: 12px;
  }
}

.coinImage {
  width: 50px;

  @include is-tablet-portrait {
    width: 55px;
  }
}

.coinValue {
  @include small-text(false);
  position: absolute;
  top: -45%;
  left: 47%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: $primary-color;
  text-align: center;
  text-transform: uppercase;

  @include is-tablet-portrait {
    top: -55%;
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0.5rem rgb(218, 211, 222));
  }
  50% {
    filter: drop-shadow(0 0 0.55rem rgb(143, 105, 152));
  }
  100% {
    filter: drop-shadow(0 0 0.5rem rgb(218, 211, 222));
  }
}

.coinGlow {
  animation: glow 2s infinite;
}