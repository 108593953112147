@import '../../../../styles/utils';

$timer-border-radius-mobile: 8px;
$timer-border-radius: 12px;

.timerBox {
  @include flex-centered(false);

  width: 90%;
  height: 30px;
  margin-top: 4rem;
  max-width: 335px;

  @include is-tablet-portrait {
    height: unset;
    max-width: 400px;
  }
}

.timerContainer {
  width: 100%;
  height: $timer-border-radius-mobile;

  background: $color-white;
  border-radius: 12px;
  overflow: hidden;

  // Bugfix for safari, overflow with border-radius not working
  mask-image: radial-gradient(white, black);

  &.isWrong {
    background: rgba($color-accent-orange, 0.25);
  }

  &.isCorrect {
    background: rgba($accent-color, 0.25);
  }

  @media (min-width: $screen-tablet-portrait) {
    max-width: 580px;
    height: $timer-border-radius;
  }
}

.time {
  width: 100%;
  height: 100%;

  background-color: $primary-color;
  border-radius: $timer-border-radius-mobile;

  &.isWrong {
    background-color: $color-accent-orange;
  }

  &.isCorrect {
    background-color: $accent-color;
  }

  @media (min-width: $screen-tablet-portrait) {
    border-radius: $timer-border-radius;
  }
}

.icon {
  width: 32px;
  margin-right: 10px;
}
