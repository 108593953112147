@import "../../../styles/index.scss";

.page {
  @include flex-centered(true, false);
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url("../../../assets/winning/winning-bg-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    background-image: url("../../../assets/winning/winning-bg-desktop.png");
    padding-top: $page-top-padding-tablet;
  }
}

.headingBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 100%;
}

.heading {
  @include h2;
}

.subHeading {
  @include sub-heading(true);
  width: 100%;
  margin-top: 0;
}

.winningBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 100%;
  margin-top: 25%;

  @include is-tablet-portrait {
    margin-top: 10%;
  }

  @include is-desktop {
    margin-top: 5%;
  }
}

.winningHeading {
  @include question-counter;
  color: $light-text-color;
  margin-top: 0;
}

.winningAmount {
  @include biggest-text($primary-color);
  text-shadow: 0 0 0 $primary-color;
  text-align: center;
}

.coins {
  @include flex-centered(false, false);
  margin-top: calc($app-height * 0.1);
  width: 70%;
  justify-content: space-between;
  color: $light-text-color;

  .coinValue {
    @include small-text(false);
    position: relative;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $primary-color;
    text-align: center;
  }

  @include is-tablet-portrait {
    width: 30%;
  }

  @include is-desktop {
    width: 25%;
  }
}

