@import "src/styles/utils";

.table {
  position: relative;
  width: 100%;
  padding: 0 35px 0 35px;
}

.rank,
.nickname,
.score {
  color: $inactive-color;
  font-family: $secondary-font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  padding-top: 8px;

  @include is-tablet-portrait {
    padding-top: 15px;
  }
}

.rank {
  flex: 1;
  text-align: left;
  font-size: 24px;
}

.nickname {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(10%);

  font-family: $root-font-family;
  font-size: $root-font-size;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.score {
  flex: 2;
  text-align: right;
  text-transform: uppercase;

  &::after {
    content: ' p';
    text-transform: lowercase;
    letter-spacing: -1px;
  }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row:first-child {
  .rank,
  .nickname,
  .score {
    color: $accent-color;
  }
}

.row:nth-child(2) {
  .rank,
  .nickname,
  .score {
    color: $primary-color;
  }
}

.row:nth-child(3) {
  .rank,
  .nickname,
  .score {
    color: $light-text-color;
  }
}

.row:nth-child(4) {
  .rank,
  .nickname,
  .score {
    color: $light-text-color;
  }
}

.empty {
  @include h2;
  color: $light-text-color;
  position: relative;
  left:50%;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 30%;
}