@import "src/styles/utils";

.tableBox {
  position: relative;
  width: 100%;
  max-width: 480px;
  overflow: auto;
  height: 75vh;
}

.table {
  position: relative;
  width: 100%;
  padding: 0 35px 0 35px;
}

.tableHeader {
  @include small-text(false);
  padding-top: 20px;
}

.row {
  position: relative;
}

.tableData {
  @include h2;
  padding-top: 10px;
  color: $inactive-color;

  @include is-tablet-portrait {
    padding-top: 20px;
  }
}

.tableHeader:nth-of-type(1),
.tableData:nth-of-type(1) {
  text-align: left;
}

.tableHeader:nth-of-type(2),
.tableData:nth-of-type(2) {
  text-align: right;

  transform: translateX(-10%);
}

.tableHeader:nth-of-type(3),
.tableData:nth-of-type(3) {
  text-align: right;
}

.row:first-child  {
  .tableData:nth-of-type(2) ,
  .tableData:nth-of-type(3) {
    color: $accent-color;
  }
}

.row:nth-child(2) {
  .tableData:nth-of-type(2) ,
  .tableData:nth-of-type(3) {
    color: $primary-color;
  }
}

.row:nth-child(3) {
  .tableData:nth-of-type(2) ,
  .tableData:nth-of-type(3) {
    color: $light-text-color;
  }
}

.row:nth-child(4) {
  .tableData:nth-of-type(2) ,
  .tableData:nth-of-type(3) {
    color: $light-text-color;
  }
}