@import 'src/styles/utils';

.wrapper {
  @include flex-centered(true);

  position: relative;
  align-items: center;

  width: 60px;
  height: 60px;

  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $primary-color;
  font-family: $secondary-font-family;
  color: $primary-color;

  transition: all 0.2s linear;

  cursor: pointer;

  &.isHelperExtraTime,
  &.isHelperNewQustion,
  &.isHelperFiftyFifty{
    background-color: transparent;
  }

  svg {
    path {
      fill: $primary-color;
    }
  }

  &:disabled:not(.isActive) {
    cursor: default;
    border: 1px solid $inactive-color;
    > .amountHelpers {
      background-color: $inactive-color;
    }

    svg {
      path {
        fill: $inactive-color;
      }
    }
  }

  &.isActive {
    color: $accent-color;
    border: 1px solid $accent-color;

    svg {
      path {
        fill: $accent-color;
      }
    }
  }

  &:active {
    background-color: transparent;
  }

  @include is-tablet-portrait {
    width: 64px;
    height: 64px;
  }

  @include is-desktop {
    width: 80px;
    height: 80px;
  }
}

.icon {
  width: auto;
  height: 50%;

  transition: transform 0.2s linear;

  @include is-tablet-portrait {
    height: 40%;
  }
}

.amountHelpers {
  @include flex-centered;

  position: absolute;
  top: -10px;
  left: 45px;

  width: 25px;
  height: 25px;

  background-color: $accent-color;
  border-radius: 50%;

  color: $color-black;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;

  transition: opacity 0.2s linear, transform 0.2s linear;

  @include is-tablet-portrait {
    left: 50px;
    font-size: 11px;
    line-height: 11px;
  }

  @include is-desktop {
    left: 60px;
    font-size: 14px;
    line-height: 11px;
  }
}
