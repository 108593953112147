@import "src/styles/utils/index";

.filler {
  flex: 1;
}

.page {
  width: 100%;
  height: 100%;
  margin: auto;
}

.countdownWrapper {
  @include flex-centered(true, false);
  justify-content: space-around;
  height: 90%;
}

.headerBox,
.categoryBox,
.countdownBox {
  text-align: center;
  width: 100%;

  .header {
    @include h2;
    opacity: 0;

    @include is-tablet-portrait {
      margin-bottom: 10px;
    }
  }

  .subHeader {
    @include sub-heading(true);
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
    opacity: 0;

    @include is-tablet-portrait {
      margin-top: 0;
    }
  }
}

.categoryBox {
  display: flex;
  flex-direction: column;

  .categoryHeader {
    @include sub-heading(true);
    text-transform: uppercase;
    opacity: 0;
  }

  .category {
    @include accent-title;
    line-height: 22px;
    margin-top: .6rem;
    opacity: 0;

    @include is-tablet-portrait {
      margin-top: 1rem;
    }

    @include is-desktop {
      margin-top: 1rem;
    }
  }
}

.countdownBox {
  display: flex;
  flex-direction: column;

  .countdownText {
    @include sub-heading(true);

    @include is-tablet-portrait {
      font-size: 20px;
      margin-top: calc($app-height * 0.01);
    }

    @include is-desktop {
      font-size: 25px;
      margin-top: calc($app-height * 0.01);
    }
  }
}


