
// Colors
$color-accent-orange: #E37232;
$color-white: #fff;
$color-light-grey: #353535;
$color-black: var(--theme-color-1);

$light-text-color: var(--light-text-color);
$primary-color: var(--theme-color-2);
$primary-text-color: var(--dark-text-color);
$secondary-color: transarent;
$accent-color: #CFEB27;
$inactive-color: #C3C3C3;
$error-text-color: #A4A4A4;
$modal-overlay: rgba(0,0,0,0.7);
$bottom-menu-bg: rgba(0,0,0,0.7);

//buttons
$button-border-radius: var(--button-border-radius);

// Fonts
$root-font-family: var(--root-font-family);
$secondary-font-family: var(--root-font-family-secondary);
$tertiary-font-family: var(--root-font-family-tertiary);
$root-font-size: var(--root-font-size);
$root-font-weight: var(--root-font-weight);

// Breakpoints
$screen-mobile-portrait: 480px;
$screen-tablet-portrait: 768px;
$screen-desktop: 1200px;
$screen-desktop-large: 1400px;

$page-top-padding-mobile: 5rem;
$page-top-padding-tablet: 6rem;

// Variable that dynamically senses viewport height
$app-height: var(--inner-height);
