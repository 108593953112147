@import "../../styles/utils";

//start view
.menuBox {
  @include flex-centered(true, false);
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  background-color: $bottom-menu-bg;
  height: 25%;
  width: 100%;
  backdrop-filter: blur(4px);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &.isFetchingData {
    opacity: 0
  }

  @include is-tablet-portrait {
    padding: 0;
    height: 24%;
  }

  @media (max-height: 650px) {
    height: 33%;
  }

  @media (max-height: $screen-mobile-portrait) and (max-width: $screen-tablet-portrait) and (orientation: landscape) {
    padding-top: 0;
    bottom: 0;
    height: 45%;
  }

  @media (max-height: $screen-tablet-portrait) and (min-width: $screen-tablet-portrait) and (orientation: landscape) {
    padding-top: 0;
    bottom: 0;
    height: 45%;
  }
}

.linkBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 480px;

  @include is-tablet-portrait {
    width: 65%;
  }

  @include is-desktop {
    width: 40%;
  }

  & button {
    width: 35%;
  }

  .topscoreButton {
    justify-content: start;
  }

  .prizesButton {
    justify-content: end;
  }

}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 480px;

  @include is-tablet-portrait {
    width: 65%;
  }

  @include is-desktop {
    width: 40%;
  }

  button:only-child {
    margin-top: 30px;

    @include is-desktop {
      margin-top: 40px;
    }
  }

  @media (max-height: $screen-mobile-portrait) and (orientation: landscape) {
    padding-top: 0;
    height: 60%;
  }
}

.playButton {
  @include tertiary-text();
  margin-bottom: 0.3rem;

  & p {
    @include small-text(false);
    font-weight: 500;
    color: $primary-text-color;
  }
}


//quiz view
.quizMenuBox {
  @include flex-centered(true, false);
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  background-color: $bottom-menu-bg;
  height: 17%;
  width: 100%;
  backdrop-filter: blur(4px);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-top: 2px solid $primary-color;
  padding-top: 3%;
  box-shadow: 0 -15px 50px rgba(#FFFFFF, 0.2);

  @include is-desktop() {
    padding: 0;
  }

  @media (max-height: $screen-mobile-portrait) and (max-width: $screen-tablet-portrait) and (orientation: landscape) {
    padding-top: 0;
    bottom: 0;
    height: 30%;
  }

  @media (max-height: 650px) {
    padding-top: 0;
    bottom: 0;
    height: 25%;
  }

  @media (max-height: $screen-tablet-portrait) and (min-width: $screen-tablet-portrait) and (orientation: landscape) {
    padding-top: 0;
    bottom: 0;
    height: 30%;
  }
}

.quizLinkBox {
  @include flex-centered(false, false);
  justify-content: space-between;
  width: 90%;
  height: 45px;
  max-width: 480px;

  & button {
    width: 40%;
  }

  @include is-tablet-portrait {
    width: 65%;
  }

  @include is-desktop {
    width: 40%;
  }

  .topscoreButton {
    justify-content: start;
  }

  .prizesButton {
    justify-content: end;
  }
}

.quizButtonBox {
  @include flex-centered(true, false);
  width: 90%;
  max-width: 480px;

  @include is-tablet-portrait {
    width: 65%;
  }

  @include is-desktop {
    width: 40%;
  }
}