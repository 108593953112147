@import "src/styles/utils/index";

.wrapper {
  @include flex-centered(true);
  width: 100%;
  min-height: 75px;
}

.imageWrapper {
  display: flex;
  position: absolute;
  margin-top: 40px;

  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 100px;

  opacity: 0;

  > .countdownNumber {
    @include biggest-text($primary-color);
  }

  @include is-tablet-portrait {
    max-width: 241px;
    max-height: 226px;
  }
}


