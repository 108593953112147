@import "../../styles/utils";

.modalWrapper {
  @include flex-centered(true, true);
  z-index: 20;
  position: fixed;
  margin: 0;
  left: 0;
  top: 0;
  background-color: $modal-overlay;

  width: 100%;
  height: 100%;
  overflow: auto;
}

.flexWrapper {
  @include flex-centered(true, true);
  flex: 1;

  @include is-tablet-portrait {
    width: 45%;
  }
}

.innerWrapper {
  @include flex-centered(true, true);
  position: relative;
  width: 100%;
  max-width: 305px;
  background-color: $color-black;
  border: 3px solid $color-accent-orange;
  border-radius: var(--modals-border-radius);
  padding: var(--modals-padding);
  text-align: center;

  .modalTitle {
    @include sub-heading(true);
    position: relative;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }

  .modalText {
    @include small-text(false);
  }
}

.closeCross {
  position: relative;
  left: 47%;
  top: -4%;

  &:hover {
    cursor: pointer;
  }
}

.buttonsWrapper {
  @include flex-centered(true);
  width: 87%;
  height: 95px;

  button {
    border-radius: 10px;
  }

  button:first-child {
    font-family: $root-font-family;
    font-size: 14px;
    font-weight: 700;
  }

  button:last-child {
    text-decoration: underline;
  }
}

