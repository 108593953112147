@import "../../../styles/utils";

.wrapper {
  @include flex-centered(false, true);
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  padding: 0;

  background-color: $secondary-color;
  border: 1px solid $color-black;
  border-radius: var(--button-border-radius);

  color: $light-text-color;
  font-weight: 600;
  font-size: $root-font-size;
  font-family: $root-font-family;

  cursor: pointer;
  transition: opacity 0.2s ease-in, color 0.2s, background-color 0.2s, background 0.2s;

  & p {
    font-weight: var(--root-font-weight);
    margin: -0.333em 0px 0px;

    @include is-tablet-portrait {
      margin: 0;
    }
  }

  @include is-tablet-portrait {
    height: 60px;
  }
}

.background {
  @include flex-centered;

  inset: 0;
  transition: opacity 0.2s ease-in, color 0.2s, background-color 0.2s;

  > img {
    width: 100%;
  }

  &.hasBlendMode {
    mix-blend-mode: multiply;
  }
}

.primary {
  background-color: $primary-color;
  color: $primary-text-color;
  border: none;
  font-weight: 900;

  box-shadow: 0 0 36px rgba(#E37232, 0.5);

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.secondary {
  @include button-text;
  background: linear-gradient(90deg, $color-black 0%, $color-light-grey 51%, $color-black 100%);
  border: 2px solid $light-text-color;
  margin-top: 5px;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.tertiary {
  background-color: transparent;
  color: $light-text-color;
  font-weight: $root-font-weight;
  border: none;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.quaternary {
  background-color: transparent;
  padding: 0;
  display: block;
  width: fit-content;
  height: fit-content;
  border: none;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.modalCloseButton {
  width: 26px;
  height: 26px;
  background: transparent;
  border: none;
  color: $primary-color;

  @include is-tablet-portrait {
    width: 34px;
    height: 34px;
  }
}

.gameInfo {
  width: 100%;
  max-width: 187px;
  height: 100%;
  max-height: 57px;

  font-family: var(--root-font-family);

  &.active {
    .background {
      opacity: 0.25;
    }
  }
}

.quizAnswer {
  background: linear-gradient(90deg, $color-black 0%, $color-light-grey 51%, $color-black 100%);
  border-radius: var(--button-border-radius);

  border: 1px solid $color-white;
  color: $color-white;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;

  &.correct {
    background: $accent-color;
    color: $primary-text-color;
    box-shadow: 0 0 30px rgba(#CFEB27, 0.5);
    border: 1px solid $accent-color;
  }

  &.incorrect {
    background: $primary-color;
    color: $primary-text-color;
    box-shadow: 0 0 30px rgba(#E37232, 0.5);
    border: 1px solid $primary-color;
  }

  &.active {
    background: $primary-color;
    color: $primary-text-color;
    box-shadow: 0 0 30px rgba(#E37232, 0.5);
    border: 1px solid $primary-color;
  }
}

.buyButton {
  @include tertiary-text();
  color: $primary-color;
  max-width: 550px;
  margin-bottom: 0.5rem;

  & p {
    @include small-text(false);
    font-weight: 500;
    color: $primary-color;

    @include is-tablet-portrait {
      margin: 0;
    }
  }

  &.active {
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }
}

.pricePlan {
  @include h2;
  background: linear-gradient(90deg, $color-black 0%, $color-light-grey 51%, $color-black 100%);
  color: $primary-color;
  border: 3px solid $primary-color;
  border-radius: $button-border-radius;
  width: 94px;
  height: 96px;
  transition: border-color 0.2s, color 0.2s, scale 0.2s;

  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @include is-tablet-portrait {
    width: 141px;
    height: 144px;
    font-size: 27px;
    font-weight: 700;
  }

  &:hover:not(:disabled) {
    scale: 1.05;
  }
}

.disabled {
  background-color: $inactive-color;
  opacity: 0.5;
  color: $error-text-color;
  pointer-events: none;
  box-shadow: none;

  & p {
    color: $error-text-color;
  }
}

