@import 'src/styles/utils';

.page {
  @include flex-centered(true, false);
  @include black-background;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.header {
  @include h2;
  margin-bottom: 5px;
  text-align: center;

  @include is-tablet-portrait {
    font-size: 24px;
  }
}

.gameResultWrapper {
  position: relative;
  padding: 10px;

  .result {
    @include flex-centered(true, true);

    .prizeHeader {
      @include question-counter;
      color: $light-text-color;
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: center;

      @include is-tablet-portrait {
        margin-top: 5rem;
      }

      @include is-desktop {
        margin-top: 3rem;
      }
    }

    .prizeValue {
      color: $accent-color;
      text-align: center;
      font-family: $secondary-font-family;
      font-size: 96px;
      font-style: normal;
      font-weight: 700;
      line-height: 0;
      text-transform: uppercase;

      @include is-tablet-portrait {
        font-size: 134px;
      }
    }

    .noPrize,
    .demoHeader {
      @include h2;
      position: relative;
      color: $accent-color;
      text-align: center;
      margin: 5rem;
    }

    &.noQuestionsSummary {
      margin-top: 15%;

      @include is-tablet-portrait {
        margin-top: 20%;
      }
    }
  }

  &.noQuestionsSummary {
    padding-top: $page-top-padding-mobile;

    @include is-tablet-portrait {
      padding-top: $page-top-padding-tablet;
    }
  }
}

.playAgainButtonBox {
  @include flex-centered(true, true);
  width: 100%;
  max-width: 480px;

  .playAgainText {
    @include sub-heading(true);
    color: $light-text-color;
    text-align: center;
    margin-top: 0;
  }

  button {
    width: 90%;
    max-width: 480px;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  @include is-tablet-portrait {
    width: 100%;
  }
}
