@import 'src/styles/utils';

.filler {
  flex: 1;
}

.page {
  @include flex-centered(true);
  flex: 1;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.quizResult {
  @include flex-centered(true, false);
  width: 100%;
  max-width: 450px;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    max-width: 80%;
    padding-top: $page-top-padding-tablet;
  }

  @include is-desktop {
    max-width: 100%;
  }
}

.header {
  @include sub-heading(false);
  color: $primary-color;
  text-transform: uppercase;
  margin-bottom: 5px;
  white-space: nowrap;
  text-align: center;

  .headerIcon {
    margin-right: 5px;
  }

  @include is-tablet-portrait {
    margin-top: 15px;
  }
}

.scoreheader {
  @include flex-centered(false, true);
  width: 50%;

  @include is-desktop {
    width: 30%;
  }
}

.totalScoreLabel {
  @include sub-heading(false);
  flex: 1;
  text-align: center;

  @include is-desktop {
    text-align: justify;
    margin-left: 100px;
  }
}

.totalScore {
  @include h2;
  flex: 1;
  padding-left: 5px;
  text-align: start;
  line-height: 15px;

  @include is-tablet-portrait {
    line-height: 44px;
    font-size: 22px;
  }
}

.questionSummaryBox {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: calc($app-height * 0.01);

  > *:not(:last-child) {
    margin-bottom: 0;
  }

  @include is-desktop {
    margin-bottom: 30px;
  }

  @media (max-width: $screen-tablet-portrait) and (max-height: 650px) {
    margin: 16px 0;
  }

  @media screen and (max-height: 670px) {
    margin: 0;
  }
}

.questionSummary {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 11px;
  opacity: 0;

  &.isCorrect {
    background-color: $accent-color;

    .numQuestion,
    .answer,
    .questionResult,
    .questionScore {
      color: $primary-text-color;
    }
  }

  &.isIncorrect {
    background-color: transparent;
    color: #A4A4A4;

    .numQuestion,
    .answer,
    .questionResult,
    .questionScore {
      color: #A4A4A4;
    }
  }

  &.isTimeoutCorrect {
    background: rgba($accent-color, 0.5);
  }

  &.isTimeoutInCorrect {
    background: rgba($primary-color, 0.15);
  }

  @media screen and (max-height: 670px) {
    padding: 3px;
  }

  @include is-desktop {
    justify-content: center;
    padding: 10px;
  }
}

.numQuestion {
  @include sub-heading(false);
  font-weight: 400;
  flex: 1 1;

  @include is-desktop {
    flex-basis: 13.33%;
    margin-left: 35%;
  }
}

.questionResult {
  @include sub-heading(true);
  position: relative;
  flex: 1;
  text-align: center;
  font-family: $secondary-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;

  @include is-desktop {
    flex-basis: 13.33%;
    margin: 0 5%;
  }
}

.answer {
  @include small-text(false);
  margin: 0;
  line-height: 1;
  text-align: center;
  text-transform: lowercase;
}

.questionScore {
  @include sub-heading(true);
  flex: 1;
  font-family: $secondary-font-family;
  text-align: end;

  &::after {
    content: ' p';

    letter-spacing: -1px;
  }

  @include is-desktop {
    flex-basis: 13.33%;
    margin-right: 35%;
  }
}

.userCoins {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 70px;
  margin-top: 1rem;
  margin-bottom: 0;
  opacity: 0;

  @include is-tablet-portrait {
    margin-top: .6rem;
    margin-bottom: .6rem;
  }

  img {
    width: 40px;
    height: 40px;

    @include is-tablet-portrait {
      width: 50px;
      height: 50px;
    }
  }
}

.button {
  align-items: center;
  width: 85%;
  max-width: 480px;
  margin-bottom: 3rem;
  opacity: 0;

  svg, span {
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  .buttonText {
    line-height: 1;
  }

  @include is-desktop {
    width: 33%;
  }
}

.buttonIcon {
  width: 40px;
  max-width: 40px;
}

.buttonText {
  @include button-text;
}
