@import 'src/styles/utils';

.filler {
  flex: 1;
}

.page {
  @include flex-centered(true, false);
  @include black-background;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.lotteryResult {
  @include flex-centered(true, false);
  width: 100%;
  max-width: 450px;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    max-width: 60%;
    padding-top: $page-top-padding-tablet;
  }
}

.header {
  @include h2;
  margin-bottom: 5px;
  text-align: center;

  @include is-tablet-portrait {
    font-size: 24px;
  }
}

.personalBest {
  @include sub-heading(false);
  width: 100%;
  margin: 0;
  color: $primary-color;
  text-align: center;

  span {
    @include sub-heading(true);
    color: $primary-color;
    margin-left: 5px;

    &::after {
      content: ' p';
      letter-spacing: -1px;
    }
  }
}

.quizResultWrapper {
  padding-bottom: 20px;
}

.gameResultWrapper {
  position: relative;
  padding: 10px;

  .result {
    @include flex-centered(true, true);

    .prizeHeader {
      @include question-counter;
      color: $light-text-color;
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: center;

      @include is-tablet-portrait {
        margin-top: 5rem;
      }

      @include is-desktop {
        margin-top: 3rem;
      }
    }

    .prizeValue {
      @include biggest-text($accent-color);
      text-align: center;
      line-height: 0;
      text-transform: uppercase;

      @include is-tablet-portrait {
        font-size: 134px;
      }
    }

    .noPrize,
    .demoHeader {
      @include h2;
      position: relative;
      color: $accent-color;
      text-align: center;
      margin: 5rem;

      @media screen and (max-height: 670px) {
        margin: 2rem;
      }
    }

    &.noQuestionsSummary {
      margin-top: 15%;

      @include is-tablet-portrait {
        margin-top: 20%;
      }
    }
  }

  &.noQuestionsSummary {
    padding-top: $page-top-padding-mobile;

    @include is-tablet-portrait {
      padding-top: $page-top-padding-tablet;
    }
  }
}

.points {
  @include flex-centered(false, true);
  height: 40px;
  min-height: 60px;
  padding: 0 20px 0 20px;
  color: #A4A4A4;

  .avatar {
    width: 28px;
    height: 28px;
    margin: 0 9px 0 0;
  }

  > p {
    flex: 1;
    margin: 0;
    font-weight: bold;
  }

  > span {
    @include h2;
    color: $accent-color;

    &::after {
      text-transform: lowercase;
      content: ' p';
      letter-spacing: -1px;
    }
  }
}

.highScoreWrapper {
  width: 100%;
  max-width: 480px;

  > p {
    margin: 0;
    line-height: 38px;
  }

  > .placement {
    @include sub-heading(false);
    display: flex;
    justify-content: space-between;
    color: $inactive-color;
    padding: 0 20px 0 20px;
    margin-bottom: 25px;

    > p {
      margin: 0;

      > span {
        @include sub-heading(true);
        color: $inactive-color;
        text-align: center;
        margin-left: 5px;
      }
    }

    @include is-desktop {
      padding: 0 10px 0 10px;
    }
  }
}

.topscoreButton {
  @include button-text;
  color: $primary-text-color;
  font-weight: 700;
  width: 90%;
  max-width: 480px;

  @include is-desktop {
    width: 100%;
  }
}

.buttonBox {
  @include flex-centered(true, true);
  width: 100%;

  @include is-desktop {
    width: 100%;
  }
}

.playAgainButtonBox {
  @include flex-centered(true, true);
  width: 100%;
  max-width: 480px;

  .playAgainText {
    @include sub-heading(true);
    color: $light-text-color;
    text-align: center;
    margin-top: 0;
  }

  button {
    width: 90%;
    max-width: 480px;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

    @include is-tablet-portrait {
        width: 100%;
    }
}
