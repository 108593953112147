@import "src/styles/utils";

.page {
  @include flex-centered(true, true);
  @include black-background;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }
}

.tutorialBox {
  @include flex-centered(true, true);
  width: 100%;
  max-width: 480px;
  height: 100%;

  .headingBox {
    @include flex-centered(true, true);
    width: 100%;
  }

  .heading {
    @include h2;
  }

  .firstSection,
  .secondSection,
  .thirdSection {
    @include flex-centered(true, true);
    text-align: center;
  }

  .firstHeading,
  .secondHeading,
  .thirdHeading {
    @include h2;
    text-transform: none;
    color: $light-text-color;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .firstParagraph,
  .secondParagraph,
  .secondParagraph1,
  .thirdParagraph {
    @include small-text(false);
    color: $light-text-color;
    line-height: 26px;
    width: 80%;

    @include is-tablet-portrait {
      width: 100%;
    }

    @include is-desktop {
      width: 100%;
    }
  }

  .tutorialImage {
    margin: 15px 0 15px 0;
    height: 50px;

    @include is-tablet-portrait {
      height: 60px;
    }
  }

  .thirdParagraph {
    @media (max-height: $screen-mobile-portrait) and (orientation: landscape) {
      margin-bottom: 10%;
    }

    @media screen and (max-height: 670px) {
      margin-bottom: 15%;
    }
  }
}