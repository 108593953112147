@import 'src/styles/utils';

.row {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;

  height: 100%;

  column-gap: 10px;
  color: $light-text-color;

  &.hasRestItems {
    cursor: pointer;
  }

  &.isRest {
    height: 0;
    margin-bottom: 0;

    opacity: 0;
    transition: height 0.3s ease, opacity 0.3s ease;

    &.isOpen {
      height: 34px;
      opacity: 1;
    }

    &.isLastRowOpen {
      width: 100%;
      height: 44px;
      margin-bottom: 10px;
      padding-bottom: 10px;

      border-bottom: 1px solid $primary-color;
    }
  }

  .date,
  .nickname,
  .score {
    color: $light-text-color;
    font-family: $root-font-family;
    font-size: $root-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    padding-top: 8px;

    @include is-tablet-portrait {
      padding-top: 15px;
    }
  }

  .date {
    position: relative;
    text-align: center;
  }

  .nickname {
    transform: translateX(30%);
    font-family: $root-font-family;
    font-size: $root-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .isFirstPlace {
    color: $accent-color;
  }

  .isSecondPlace {
    color: $primary-color;
  }

  .score {
    text-align: right;
    text-transform: uppercase;
    font-family: $secondary-font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    &::after {
      content: ' p';
      text-transform: lowercase;
      letter-spacing: -1px;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: 1%;
    transform: translate(-20%, -20%) rotate(0deg);

    transition: transform 0.3s ease;

    @include is-tablet-portrait {
      transform: translate(0, 0) rotate(0deg);
    }

    > path {
      fill: $light-text-color;
    }

    &.isOpen {
      transform: rotate(180deg);
    }
  }
}
