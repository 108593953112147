@import "src/styles/utils";

.filler {
  flex: 1;
}

.page {
  @include flex-centered(true, false);
  flex: 1;
  justify-content: space-between;
  width: 100%;

  &.isImageQuestion {
    height: 100%;

    @include is-tablet-portrait {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @include is-tablet-portrait {
    padding-bottom: 0;
  }

  @include is-desktop {
    max-height: 800px;
  }

  @media (min-width: $screen-desktop) and (min-height: 1300px) {
    justify-content: center;

    max-height: unset;
    margin-bottom: 25%;
  }
}

.questionContainer{
  @include flex-centered(true);
  flex: 1;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  min-height: 224px;
  max-height: 234px;
  margin-bottom: 10px;

  .questionCounter {
    @include question-counter;
    text-align: center;
  }

  .questionText {
    @include h2;
    text-transform: none;
    color: $light-text-color;
    margin: 5px 40px 0 40px;
    width: 90%;
    max-width: 480px;
    text-align: center;
  }

  .imageWrapper {
    @include flex-centered(true);
    justify-content: flex-end;

    width: 320px;
    height: 135px;

    margin-top: 3%;

    .image {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: top;
      border-radius: 20px;

      @include is-tablet-portrait {
        width: auto;
        min-width: 100%;
        height: 100%;
        border-radius: 24px;
      }
    }

    @include is-tablet-portrait {
      width: 400px;
      height: 180px;
      border-radius: 24px;
    }

    @include is-desktop {
      width: 480px;
      height: 190px;
      border-radius: 30px;
    }
  }

  .resultText {
    @include accent-title;
    position: relative;
    text-align: center;
    line-height: 22px;
    text-transform: uppercase;
    text-shadow: 0 0 30px rgba(#CFEB27, 0.5);

    &::before {
      content: '+';
      letter-spacing: -1px;
    }

    &::after {
      content: ' p';
      letter-spacing: -1px;
    }
  }

  &.isImageQuestion {
    justify-content: center;
    width: 100%;

    color: $color-white;

    @media (min-width: $screen-desktop) and (min-height: 1300px) {
      top: 50px;
    }

    @media (max-height: 650px) and (max-width: 400px) {
      min-height: 130px !important;
      max-height: 140px;
    }

    @media (min-width: $screen-tablet-portrait) and (max-height: 700px) {
      min-height: 290px;
      max-height: 300px;
    }

    @media (max-height: 650px) {
      min-height: 130px;
      max-height: 140px;
    }

    @media (min-width: $screen-tablet-portrait) and (min-height: 800px) {
      min-height: 310px;
      max-height: 320px;
    }

    @media (max-width: $screen-tablet-portrait) and (max-height: 549px) {
      min-height: 190px;
      max-height: 200px;
    }

    @media (min-width: $screen-desktop) and (min-height: 800px) {
      min-height: 310px;
      max-height: 320px;
    }

    @media (min-width: $screen-desktop) and (min-height: 1300px) {
      min-height: 400px;
      max-height: 410px;
    }
  }

  @media (min-width: $screen-tablet-portrait) and (max-height: 700px) {
    max-height: 300px;
  }

  @media (max-height: 650px) and (max-width: 400px) {
    min-height: 140px !important;
    max-height: 150px;
  }

  @media (max-width: $screen-tablet-portrait) and (max-height: 549px) {
    min-height: 190px;
  }

  @media (min-height: 700px) {
    max-height: 300px;
  }

  @media (min-width: $screen-tablet-portrait) and (min-height: 800px) {
    max-height: 468px;
  }

  @media (min-width: $screen-desktop) and (max-height: 800px) {
    min-height: 200px;
  }

  @media (min-width: $screen-desktop) and (min-height: 800px) {
    max-height: 380px;
  }

  @media (min-width: $screen-desktop) and (min-height: 1300px) {
    max-height: 468px;
  }
}

.buttonWrapper {
  display: grid;

  grid-template-columns: 1fr;
  align-items: center;

  width: 100%;
  max-width: 335px;
  min-height: 140px;
  column-gap: 10px;
  row-gap: 12px;

  .button {
    white-space: unset;

    @media (max-height: 600px) {
      height: 40px;
    }
  }

  @include is-tablet-portrait {
    max-width: 400px;
  }

  @include is-desktop {
    max-width: 480px;
  }
}

.helpers {
  @include flex-centered(false, false);
  position: fixed;
  width: 85%;
  max-width: 335px;
  justify-content: space-between;
  bottom: 1.5rem;

  min-height: 115px;

  @media screen and (max-height: 670px) {
    bottom: 0;
    min-height: 0;
  }

  @include is-tablet-portrait {
    width: 50%;
    max-width: 400px;
    min-height: 120px;
    bottom: 3rem;

    @media (min-height: 800px) {
      min-height: 133px;
    }
  }

  @include is-desktop {
    width: 40%;
    max-width: 490px;
    bottom: 2rem;

    @media (max-height: 800px) {
      bottom: 1rem;
    }
  }
}