@import "src/styles/utils";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }

  @media (max-height: $screen-mobile-portrait) and (orientation: landscape) {
    min-height: 480px;
  }
}

.headingBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 100%;
}

.heading {
  @include h2;
}

.subHeading {
  @include sub-heading(true);
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
}

.subHeadingAmount {
  width: 100%;
  @include sub-heading(false);
  margin-top: 0.3rem;
}

.purchaseBox {
  @include flex-centered(true, true);
  width: 100%;
}

.pricePlansBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  justify-items: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  width: 65%;

  & > button:nth-child(3):nth-last-child(1) {
    grid-column: span 2;
  }

  & > button:nth-child(2):nth-last-child(1) {
    top: 50%;
  }

  & > button:nth-child(1):nth-last-child(2) {
    top: 50%;
  }

  & > button:only-child {
    top: 50%;
    grid-column: span 2;
  }

  .isSelected {
    border-color: $accent-color;
    color: $accent-color;
  }

  @include is-tablet-portrait {
    width: 40%;
  }

  @include is-desktop {
    width: 27%;
  }

  @media (max-height: 670px) {
    margin-top: 0;
  }
}

.purchaseSuccessBox {
  position: absolute;
  bottom: 20%;
  color: $light-text-color;

  @media screen and (max-height: 670px) {
    bottom: 24%;
  }

  .purchaseSuccessText {
    @include sub-heading(false);
  }
}
