@mixin flex-centered($direction-column: false, $justify: false) {
  display: flex;
  align-items: center;
  justify-content: center;

  @if $direction-column {
    flex-direction: column;
  }

  @if $justify {
    justify-content: center;
  }
}

@mixin is-tablet-portrait() {
  @media (min-width: $screen-tablet-portrait) {
    @content;
  }
}

@mixin is-desktop() {
  @media (min-width: $screen-desktop) {
    @content;
  }
}

@mixin accent-title() {
  color: $accent-color;
  font-family: $secondary-font-family;
  font-weight: bold;
  font-size: var(--title-font-size);
  margin-top: 0.5rem;

  @include is-tablet-portrait {
    font-size: var(--title-font-size-tablet);
    margin-top: 1rem;
  }
}

@mixin question-counter() {
  color: $primary-color;
  text-align: center;
  font-weight: bold;
  font-family: $secondary-font-family;
  font-size: 18px;
  text-transform: uppercase;

  @include is-tablet-portrait {
    font-size: 35px;
  }
}

@mixin sub-heading($is-bold: true) {
  font-size: 14px;
  color: $light-text-color;
  font-family: $root-font-family;
  line-height: 25px;

  @if $is-bold {
    font-weight: bold;
  }

  @include is-tablet-portrait {
    font-size: var(--root-font-size-tablet);
  }

  @include is-desktop {
    font-size: var(--root-font-size-desktop);
    max-width: 450px;
    align-self: center;
  }
}

@mixin small-text($is-bold: true) {
  font-size: var(--small-font-size);
  font-weight: var(--root-font-weight);
  color: $light-text-color;
  font-family: $root-font-family;
  line-height: 20px;

  @include is-tablet-portrait {
    font-size: var(--root-font-size-tablet);
  }

  @include is-desktop {
    font-size: var(--root-font-size-desktop);
  }

  @if $is-bold {
    font-weight: bold;
  }
}

@mixin h2 {
  font-size: var(--large-mobile-font-size);
  font-weight: 600;
  font-family: $secondary-font-family;
  text-transform: uppercase;
  color: $primary-color;

  @include is-tablet-portrait {
    font-size: var(--large-font-size-tablet);
  }

  @include is-desktop {
    font-size: var(--large-font-size-desktop);
  }
}

@mixin tertiary-text() {
  font-size: var(--large-mobile-font-size);
  font-weight: 900;
  font-family: $tertiary-font-family;
  color: $color-black;
  line-height: 20px;

  @include is-tablet-portrait {
    font-size: var(--large-font-size-tablet);
  }

  @include is-desktop {
    font-size: var(--large-font-size-desktop);
  }
}

@mixin button-text() {
  color: $light-text-color;
  text-align: center;
  font-family: $root-font-family;
  font-size: var(--root-font-size);
  font-weight: 500;
  text-transform: uppercase;
}

@mixin biggest-text($color: color) {
  font-size: 96px;
  font-weight: 700;
  font-family: $secondary-font-family;
  color: $color;
  text-align: center;
  line-height: 0;

  @include is-tablet-portrait {
    font-size: 130px;
  }
}

@mixin black-background() {
  background: linear-gradient(
                  90deg,
                  rgba(#000000, 0.2) 0%,
                  rgba(#000000, 1) 26%,
                  rgba(#000000, 1) 78%,
                  rgba(#000000, 0.2) 100%
  ), url('../../assets/background/black-background.png');
  background-position: center center;
  background-size: cover;

  @include is-tablet-portrait {
    background: linear-gradient(
                    90deg,
                    rgba(#000000, 0.2) 0%,
                    rgba(#000000, 1) 26%,
                    rgba(#000000, 1) 78%,
                    rgba(#000000, 0.2) 100%
    ), url('../../assets/background/black-background.png');
    background-position: center center;
    background-size: cover;
  }
  @include is-desktop {
    background: linear-gradient(
                    90deg,
                    rgba(#000000, 0.2) 0%,
                    rgba(#000000, 1) 26%,
                    rgba(#000000, 1) 78%,
                    rgba(#000000, 0.2) 100%
    ), url('../../assets/background/black-background.png');
    background-position: center center;
    background-size: cover;
  }
}

