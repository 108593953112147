@import "src/styles/utils";

.page {
  @include flex-centered(true);
  justify-content: flex-start;
  width: 100%;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }
}

.headingBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 100%;
}

.heading {
  @include h2;
  margin-bottom: 0;
}

.coinFlipBox {
  margin-top: 20%;
  max-width: 340px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 140px;

  .coin:last-child {
    grid-column: 2;
  }

  .coin {
    transform: translateX(10%);
  }

  .coin:nth-child(1),
  .coin:nth-child(3),
  .coin:nth-child(4),
  .coin:nth-child(6) {
    transform: translateY(40%) translateX(10%);

  }

  @media (max-width: 335px) and (min-height: 650px) {
    margin-bottom: 0;
  }

  @media (min-width: 335px) and (min-height: 650px) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }

  @include is-tablet-portrait {
    margin-top: 15%;
  }

  @include is-desktop {
    margin-top: 10%;
  }

  .coinFlipText {
    @include sub-heading(true);
    margin-left: 35px;
    text-transform: uppercase;
    margin-bottom: 5px;
    opacity: 0;
    transition: opacity 0.2s ease-out;

    &.isFlipped {
      opacity: 1;
    }
  }

  img {
    width: 100px;
  }
}