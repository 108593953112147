@import "src/styles/utils";

.page {
  @include flex-centered(true, true);
  padding-top: $page-top-padding-mobile;
  overflow-x: hidden;

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }

  @media (max-height: 480px) and (orientation: landscape) {
      min-height: 480px;
      overflow: auto;
  }
}

.buttonBox {
  position: relative;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 480px;

  border-radius: var(--button-border-radius);
  border: 1px solid $primary-color;

  @include is-tablet-portrait {
    width: 100%;
  }
  @include is-desktop {
    width: 100%;
  }
}

.navButton {
  @include flex-centered(false, true);
  @include small-text(false);
  flex: 1;
  width: 45%;
  height: 30px;

  background-color: transparent;
  border-radius: 6px;
  border: none;

  transition: text-decoration-color 0.3s ease-out, border-radius, opacity 0.3s ease;

  &.isActive {
    z-index: 1;
    background-color: $primary-color;
    font-weight: bold;
  }

  &:hover {
    opacity: 0.7;
  }
}

.prizes {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(20vh, 25vh);
  gap: calc(.1rem + 2vh) 0;
  margin-top: 3%;

  @media (max-height: 700px) and (orientation: landscape) {
    grid-auto-rows: minmax(190px, 200px);
    width: 100%;
    max-width: 480px;
    margin-left: 7%;
  }

  @include is-tablet-portrait {
    width: 100%;
    max-width: 480px;
    margin-left: 5%;
  }

  @include is-desktop {
    width: 100%;
    gap: calc(.1rem + 1vh) 0;
  }
}

.firstImage {
  width: 214px;
  margin-left: -3vw;

  @include is-tablet-portrait {
    margin-left: 0;
  }
}

.secondImage {
  width: 180px;
}

.thirdImage {
  width: 170px;
  margin-left: 5%;
}

.firstPlace {
  color: $light-text-color;
  margin-top: 30px;

  p {
    @include small-text(false);
  }

  span {
    @include h2;
    font-size: 25px;
    line-height: 35.439px;

    color: #858585;
    @supports ((background-clip: text) or (-webkit-background-clip: text)) {
      color: transparent;
      background-image: linear-gradient(to right, transparent 0%, transparent 45%, white 50%, transparent 55%, transparent 100%), linear-gradient(90deg,
                      #c78c48,
                      #cf9147 9.4%,
                      #cf9348 9.4%,
                      #b2763e 33.6%,
                      #ad743c 35.7%,
                      #ac723d 46.9%,
                      #b0773d 51.7%,
                      #b0793e 52.1%,
                      #c4914c 64.6%,
                      #c99750 68.5%,
                      #ce9e54 73.2%,
                      #f4d188);
      background-position: 110% 0, 0 0;
      background-size: 200% auto, auto;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 2s ease-in-out 2 alternate-reverse 2s;
    }
  }
}

.secondPlace {
  color: $light-text-color;
  margin-top: 20px;
  margin-left: 10%;

  p {
    @include small-text(false);
  }

  span {
    @include h2;
    font-size: 25px;
    line-height: 35.439px;

    color: #858585;
    @supports ((background-clip: text) or (-webkit-background-clip: text)) {
      color: transparent;
      background-image: linear-gradient(to right, transparent 0%, transparent 45%,white 50%,transparent 55%,transparent 100%), linear-gradient(270deg, #8c8c8c 1.3%, #999 15%, #868686 29.6%, #828282 29.6%, #7d7d7d 31.8%, #797979 31.8%, #6a6a6a 38.9%, #d3d3d3);
      background-position: 110% 0, 0 0;
      background-size: 200% auto, auto;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 2s ease-in-out 2 alternate-reverse 2s;
    }
  }

  @include is-desktop {
    margin-left: 3vw;
  }
}

.thirdPlace {
  color: $light-text-color;
  margin-left: 5vw;

  p {
    @include small-text(false);
  }

  span {
    @include h2;
    font-size: 25px;
    line-height: 35.439px;

    color: #858585;
    @supports ((background-clip: text) or (-webkit-background-clip: text)) {
      color: transparent;
      background-image: linear-gradient(to right,
              transparent 0%,
              transparent 45%,
              white 50%,
              transparent 55%,
              transparent 100%),
      linear-gradient(90deg,
                      #CD7F32,
                      #cf9147 9.4%,
                      #cf9348 9.4%,
                      #A75B10 33.6%,
                      #ad743c 35.7%,
                      #ac723d 46.9%,
                      #A75B10 51.7%,
                      #A75B10 52.1%,
                      #F3A960 64.6%,
                      #F3A960 68.5%,
                      #F3A960 73.2%,
                      #FFD8B1);
      background-position: 110% 0, 0 0;
      background-size: 200% auto, auto;
      -webkit-background-clip: text;
      background-clip: text;
      animation: shine 2s ease-in-out 2 alternate-reverse 2s;
    }
  }

  @include is-desktop {
    margin-left: 0;
  }
}

.firstPlaceLabel {
  display: flex;
  flex-direction: row;
  align-items: center;

  img  {
    width: 36px;
  }

  span {
    @include sub-heading(true);
    color: $light-text-color;
    margin-left: 5px;
    text-transform: uppercase;
  }
}

.secondPlaceLabel {
  display: flex;
  flex-direction: row;
  align-items: center;

  img  {
    width: 21px;
  }

  span {
    @include sub-heading(true);
    color: $light-text-color;
    margin-left: 5px;
    text-transform: uppercase;
  }
}

.thirdPlaceLabel {
  display: flex;
  flex-direction: row;
  align-items: center;

  img  {
    width: 21px;
  }

  span {
    @include sub-heading(true);
    color: $light-text-color;
    margin-left: 5px;
    text-transform: uppercase;
  }
}

.bottomBlock {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-align: center;

  @include is-tablet-portrait {
    width: 100%;
    max-width: 480px;
  }
}
.info {
  @include small-text(false);
}

